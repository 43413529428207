import React from "react";
import { Link } from "@reach/router";
import { xor } from 'lodash';

export const areArraysEqual = (array1, array2) => {
  let areEqual = array1.length === array2.length;
  if (areEqual) {
    areEqual = xor(array1, array2).length === 0;
  }

  return areEqual;
};

/* istanbul ignore next */
export const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

/* istanbul ignore next */
export const stopEvent = (event) => {
  if (event.preventDefault) { event.preventDefault(); }
  if (event.stopPropagation) { event.stopPropagation(); }
};

/* istanbul ignore next */
export const convertSecondsToTime = (seconds) => {
  let minutes = Math.trunc(seconds / 60);
  seconds = seconds - (minutes * 60);
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${minutes}:${seconds}`;
};

/**
 * Returns scroll object based on passed data
 *
 * @param {number} contentHeight
 * @param {Object[]} items
 * @param {number} rowHeight
 * @param {number} reductionHeight
 *
 * @returns {Object}
 */
export const getScrollHeight = (contentHeight, items, rowHeight, reductionHeight) => {
  let newScroll = {};
  const itemCount = items.length;
  if (itemCount) {
    const y = contentHeight ? contentHeight - reductionHeight : 240;
    newScroll = {
      y: y,
      x: 'max-content'
    };
    if (itemCount * rowHeight <= newScroll.y) {
      newScroll = {};
    }
  }

  return newScroll;
};

/**
 * Returns a list of render items
 *
 * @param {Object[]} data
 * @param {String} key
 *
 * @returns {JSX.Element[]}
 */
export const renderListItems = (data, key) => {
  const mappedItems = [];
  let item = 0;
  const itemCount = data.length;
  let currentItem;
  while (item < itemCount) {
    currentItem = data[item];
    mappedItems.push((
      <span key={`${key}-${currentItem.id || currentItem.name}`}>
        {currentItem.name}
      </span>
    ));
    if (item < itemCount - 1) {
      mappedItems.push(', ');
    }
    item++;
  }

  return mappedItems;
};

/**
 * Returns a list of anchor clickable items
 *
 * @param {Object[]} data
 * @param {Function} onClick
 * @param {String} key
 * @param {Function} getLinkRouteFn
 * @param {String|Function} [property]
 *
 * @returns {JSX.Element[]}
 */
export const renderListClickableItems = (data, onClick, key, getLinkRouteFn, property) => {
  const mappedItems = [];
  let item = 0;
  const itemCount = data.length;
  let currentItem;
  while (item < itemCount) {
    currentItem = data[item];
    mappedItems.push(renderListClickableItem(currentItem, onClick, key, getLinkRouteFn, property));
    if (item < itemCount - 1) {
      mappedItems.push(', ');
    }
    item++;
  }

  return mappedItems;
};

export const moveInArray = (arr, from, to) => {
  // Make sure a valid array is provided
  if (Object.prototype.toString.call(arr) !== '[object Array]') {
    throw new Error('Please provide a valid array');
  }
  // Delete the item from it's current position
  let item = arr.splice(from, 1);
  // Move the item to its new position
  arr.splice(to, 0, item[0]);
};

export function checkVisible(elm) {
  if (elm) {
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }
  return false;
}

/**
 * Returns anchor link for clickable items
 *
 * @param {Object} item
 * @param {Function} onClick
 * @param {String} key
 * @param {Function} getLinkRouteFn
 * @param {String|Function} [property]
 *
 * @returns {JSX.Element}
 */
export const renderListClickableItem = (item, onClick, key, getLinkRouteFn, property) => {
  property = property ? property : 'name';
  const { id } = item;
  const href = getLinkRouteFn ? getLinkRouteFn(id) : '';
  onClick = href ? null : onClick.bind(this, id);
  const text = typeof property === 'function' ? property(item) : item[property];
  return (
    <Link onClick={onClick}
      className="NavigationLink"
      key={`${key}-${id}`}
      to={href}>
      {text}
    </Link>
  );
};

export const isWeb = () => window.innerWidth > 768 ? true : false;

export const columnWidth = width => isWeb() ? width : '';

export const renderUScurrency = (value, hideSymbol = false) => {
  if (!value) return null;

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (hideSymbol) {
    return USDollar.format(value).replace('$', '');
  }

  return USDollar.format(value);
};